import { BaseCustomField } from '@air/api/types';
import {
  At,
  Calendar,
  Camera,
  Copyright,
  Fields,
  FolderOpen,
  Import,
  Key,
  Location,
  Palette,
  Plus,
  Tag,
  User,
  Video,
} from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { SelectedFilter } from '~/store/filters/types';
import { IconType } from '~/types/icons';

export const AllDateFilters = ['dateCreated', 'dateUploaded', 'dateModified', 'dateTaken'] as const;
export type DateFilters = (typeof AllDateFilters)[number];

export const AllAudioVisualFilters = ['videoFrameRate', 'videoAspectRatio', 'audioCoding', 'audioSampleRate'] as const;
export type AudioVisualFilters = (typeof AllAudioVisualFilters)[number];

export const AllLocationFilters = ['country', 'city', 'state'] as const;
export type LocationFilters = (typeof AllLocationFilters)[number];

export type FilterType =
  | 'type'
  | 'uploader'
  | 'color'
  | 'customField'
  | 'tags'
  | 'date'
  | 'extension'
  | 'source'
  | 'other'
  | 'creator'
  | 'importedKeywords'
  | 'copyright'
  | 'camera'
  | 'audioVisual'
  | 'location'
  | AudioVisualFilters
  | LocationFilters
  | DateFilters;

const filtersInOrder: FilterType[] = [
  'type',
  'uploader',
  'color',
  'camera',
  'creator',
  'copyright',
  'importedKeywords',
  'extension',
  'location',
  'city',
  'state',
  'country',
  'source',
  'customField',
  'tags',
  'audioVisual',
  'audioCoding',
  'audioSampleRate',
  'videoAspectRatio',
  'videoFrameRate',
  'date',
  'dateCreated',
  'dateModified',
  'dateTaken',
  'dateUploaded',
  'other',
];

export const filtersOrder = filtersInOrder.reduce<Partial<Record<FilterType, number>>>((acc, filter, index) => {
  acc[filter] = index;
  return acc;
}, {});

export const sortFiltersByOrder = (filterA: FilterType, filterB: FilterType) =>
  (filtersOrder[filterA] ?? 0) - (filtersOrder[filterB] ?? 0);

export type FilterBaseMenuOption = {
  id: FilterType;
  subOptions?: (DropdownMenuOption & { id: FilterType | string; 'data-testid'?: string; onClick?: () => void })[];
  label: string;
  icon?: IconType;
};

export type CustomFieldFilterType = Pick<BaseCustomField, 'id' | 'name' | 'type'>;

export const baseFiltersOptions: Record<FilterType, FilterBaseMenuOption> = {
  type: { id: 'type', label: 'Type', icon: FolderOpen },
  uploader: { id: 'uploader', label: 'Uploader', icon: User },
  color: { id: 'color', label: 'Color', icon: Palette },
  customField: { id: 'customField', label: 'Custom field', icon: Fields },
  tags: { id: 'tags', label: 'Tags', icon: Tag },
  date: { id: 'date', label: 'Date', icon: Calendar },
  dateCreated: { id: 'dateCreated', label: 'Date created', icon: Calendar },
  dateUploaded: { id: 'dateUploaded', label: 'Date uploaded', icon: Calendar },
  dateTaken: { id: 'dateTaken', label: 'Date taken', icon: Calendar },
  dateModified: { id: 'dateModified', label: 'Date modified', icon: Calendar },
  source: { id: 'source', label: 'Source', icon: Import },
  extension: { id: 'extension', label: 'Extension', icon: At },
  other: { id: 'other', label: 'More filters', icon: Plus },
  importedKeywords: { id: 'importedKeywords', label: 'Imported keywords', icon: Key },
  copyright: { id: 'copyright', label: 'Copyright', icon: Copyright },
  creator: { id: 'creator', label: 'Creator', icon: User },
  camera: { id: 'camera', label: 'Camera', icon: Camera },
  audioVisual: { id: 'audioVisual', label: 'Audio/Visual', icon: Video },
  videoFrameRate: { id: 'videoFrameRate', label: 'Video Frame Rate', icon: Video },
  location: { id: 'location', label: 'Location', icon: Location },
  country: { id: 'country', label: 'Country', icon: Location },
  city: { id: 'city', label: 'City', icon: Location },
  state: { id: 'state', label: 'State/Province', icon: Location },
  videoAspectRatio: { id: 'videoAspectRatio', label: 'Signal Aspect Ratio', icon: Video },
  audioCoding: { id: 'audioCoding', label: 'Audio Coding', icon: Video },
  audioSampleRate: { id: 'audioSampleRate', label: 'Audio Sample Rate', icon: Video },
};

export type FilterSelectType = (filter: FilterType, customField?: CustomFieldFilterType) => void;

export interface GetFiltersMenuOptionsParams {
  onSelect: FilterSelectType;
  customFields: CustomFieldFilterType[];
  selectedFilters?: SelectedFilter[];
  availableFilters: FilterType[];
}
