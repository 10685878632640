import { Board, PublicBoard } from '@air/api/types';
import { GallerySection } from '@air/component-gallery-view';
import { useBreakpointsContext } from '@air/provider-media-query';
import { constant, noop } from 'lodash';
import { useCallback, useMemo } from 'react';

import { CardSize } from '~/classes/CardSizeStore';
import { GalleryItemType, GalleryViewRender } from '~/components/Gallery/types';
import { useGallerySectionFooter } from '~/components/Gallery/useGallerySectionFooter';
import { useGallerySectionHeader } from '~/components/Gallery/useGallerySectionHeader';
import { GalleryMetadata } from '~/components/PrivateGallery/PrivateGalleryView';
import { isBoardDragType } from '~/components/Shared/Drag/dragTypes';
import { WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN } from '~/constants/WorkspaceSpacing';
import useCSSGridLikeLayout from '~/hooks/useCSSGridLikeLayout';
import { UseGalleryDataInfo } from '~/swr-hooks/gallery/types';

export const BoardGalleryCardDesktopSize: { [key in CardSize]: number } = {
  small: 153,
  medium: 204,
  large: 408,
  'extra-large': 612,
};

export const BoardGalleryCardMobileSize: { [key in CardSize]: number } = {
  small: 120,
  medium: 160,
  large: 220,
  'extra-large': 280,
};

export interface UseGalleryBoardParams<B> {
  data?: UseGalleryDataInfo<B>;
  containerWidth: number;
  renderBoard: ((props: GalleryViewRender<B>) => JSX.Element) | undefined;
  loadMore?: () => void;
  containerHorizontalPadding?: number;
  itemHeight: number;
  isSelectable?: (board: B) => boolean;
  onSectionCollapse?: (isCollapsed: boolean) => void;
}

export const useGalleryBoards = <B extends Board | PublicBoard>({
  renderBoard,
  data,
  containerWidth,
  loadMore = noop,
  containerHorizontalPadding = WKSPC_DESKTOP_HORIZONTAL_PADDING_WITH_SIDENAV_OPEN,
  itemHeight,
  isSelectable = constant(false),
  onSectionCollapse,
}: UseGalleryBoardParams<B>): GallerySection<GalleryMetadata> | null => {
  const { isAboveSmallScreen, isAboveMediumScreen } = useBreakpointsContext();

  const { boxes: sectionBoxes } = useCSSGridLikeLayout({
    height: itemHeight,
    minWidth: itemHeight,
    itemCount: data?.items?.length ?? 0,
    containerWidth: containerWidth,
    itemHorizontalSpacing: !isAboveSmallScreen ? 8 : 24,
    containerHorizontalPadding: containerHorizontalPadding,
  });

  const renderItem = useCallback(
    (index: number) => {
      const board = data?.items[index];
      return board && renderBoard
        ? renderBoard({
            box: sectionBoxes[index],
            data: board,
            index,
          })
        : null;
    },
    [data?.items, renderBoard, sectionBoxes],
  );

  const verticalItemsSpacing = useMemo(() => {
    let verticalSpacing = 36;

    if (!isAboveMediumScreen) verticalSpacing = 24;
    if (!isAboveSmallScreen) verticalSpacing = 8;

    return verticalSpacing;
  }, [isAboveMediumScreen, isAboveSmallScreen]);

  const getItemData = useCallback(
    (index: number): GalleryMetadata => ({
      type: GalleryItemType.board,
      isSelectable: !!data?.items[index] ? isSelectable(data?.items[index]) : false,
      itemId: data?.items[index]?.id || '',
      canDragTo: (item) => {
        const isAncestorOfAnyBoard = !!data?.items[index]?.ancestors?.find(({ id }) => id === item.id);
        if (isAncestorOfAnyBoard || item.mixedTypes) {
          return false;
        }
        return isBoardDragType(item.type);
      },
    }),
    [data?.items, isSelectable],
  );

  const { isHeaderCollapsed, header } = useGallerySectionHeader({
    isFirstSection: true,
    title: `Boards (${data?.total?.toLocaleString()})`,
    containerWidth,
    containerPadding: containerHorizontalPadding,
    onSectionCollapse,
  });

  const footer = useGallerySectionFooter({
    isLoading: !!data?.isLoading,
    loadMore,
    hasMore: !!data?.hasMore,
    containerWidth,
  });

  if (!data?.items?.length && !data?.isLoading) {
    return null;
  }

  return {
    header: !!data?.items?.length ? header : undefined,
    footer: isHeaderCollapsed ? undefined : footer,
    items: {
      render: renderItem,
      boxes: isHeaderCollapsed ? [] : sectionBoxes,
      verticalItemsSpacing,
      getData: getItemData,
    },
  };
};
