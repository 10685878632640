import { Button, ButtonProps, Text } from '@air/zephyr';

export type SelectionActionBarLinkProps = ButtonProps;

export const SelectionActionBarLink = ({ children, ...restOfProps }: SelectionActionBarLinkProps) => {
  return (
    <Button
      size="small"
      tx={{
        px: 4,
        color: 'jay200',
        fontWeight: 'regular',

        '&:hover, &:focus': {
          backgroundColor: 'transparent',
          color: 'jay400',
        },
      }}
      variant="button-unstyled"
      {...restOfProps}
    >
      <Text as="span" tx={{ color: 'inherit' }} variant="text-ui-16">
        {children}
      </Text>
    </Button>
  );
};
