import React, { memo, ReactNode } from 'react';

import { centeredTableCells, getTableColumnWidth } from '~/components/TableView/types';
import { TableCell } from '~/components/TableView/ui';
import { ConfigVisibilityToggleType, getVisibilityToggleTypeFromString } from '~/constants/configViews';
import { TABLE_ROW_CELL } from '~/constants/testIDs';
import { configViewOptionsSelector } from '~/store/configViews/selectors';
import { ConfigViewOption } from '~/store/configViews/types';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface ConfigurableTableRowsProps {
  rowInfo: Record<ConfigVisibilityToggleType, string>;
  renderCustomFieldTableCellComponent: (option: ConfigViewOption) => ReactNode;
}

export const ConfigurableTableCells = memo(
  ({ rowInfo, renderCustomFieldTableCellComponent }: ConfigurableTableRowsProps) => {
    const configViewOptions = useAirSelector(configViewOptionsSelector);

    return (
      <>
        {configViewOptions.map((option) => {
          if (option.isCustomField && option.customFieldId && option.isVisible) {
            return renderCustomFieldTableCellComponent(option);
          }
          const toggleType = getVisibilityToggleTypeFromString(option.fieldName);
          if (!toggleType || !option.isVisible) return null;

          return (
            <TableCell
              data-testid={`${TABLE_ROW_CELL}-${option.fieldName}`}
              key={option.fieldName}
              width={getTableColumnWidth(option.fieldName)}
              tx={{
                maxWidth: getTableColumnWidth(option.fieldName),
                textAlign: centeredTableCells.includes(toggleType) ? 'center' : 'left',
              }}
            >
              {rowInfo[toggleType]}
            </TableCell>
          );
        })}
      </>
    );
  },
);

ConfigurableTableCells.displayName = 'ConfigurableTableRows';
