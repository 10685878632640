import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, forwardRef } from 'react';

import { BOARD_PAGE_TABLE_HEADER } from '~/constants/testIDs';

import { Container } from './ui';

export type TableHeaderProps = Pick<ComponentProps<'div'>, 'children' | 'className' | 'style'> & {
  innerStyle?: ComponentProps<'div'>['style'];
};

const TableHeader = forwardRef<HTMLDivElement, TableHeaderProps>(
  ({ children, className, style, innerStyle, ...restOfProps }, ref) => {
    return (
      <Container
        className={tailwindMerge(
          'ios-safari-fixed-element-hack sticky z-[1] mb-1 flex min-h-[50px] items-center overflow-hidden border-b border-b-grey-5 bg-grey-1 text-12 font-semibold text-grey-9 transition-all',
          className,
        )}
        {...restOfProps}
        ref={ref}
        data-testid={BOARD_PAGE_TABLE_HEADER}
        style={style}
      >
        <div className="flex w-full items-center" style={innerStyle}>
          {children}
        </div>
      </Container>
    );
  },
);

TableHeader.displayName = 'BoardPageTableHeader';

export default TableHeader;
