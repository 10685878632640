import { TransactionModal } from '@air/zephyr';
import pluralize from 'pluralize';
import { memo, useCallback, useMemo, useState } from 'react';

import { DateInput } from '~/components/Zephyr/DateInput/DateInput';
import { parseAirDateToISO } from '~/utils/DateUtils';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditDateValueCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  onSave: (params: {
    assetIds: string[];
    boardIds: string[];
    baseCustomField: BulkEditCustomFieldModalProps['field'];
    nextDateValue: string | undefined;
  }) => Promise<void>;
}

export const BulkEditDateValueCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues,
    onClose,
    onSave,
  }: AirModalProps<BulkEditDateValueCustomFieldModalProps>) => {
    const allHaveSameValue = useMemo(() => {
      const baseValue = initialValues[0]?.dateValue;
      return initialValues.every((cf) => cf.dateValue === baseValue);
    }, [initialValues]);

    const originalValue = allHaveSameValue ? initialValues[0]?.dateValue : undefined;
    const originalDateValue = originalValue ? parseAirDateToISO(originalValue) : undefined;
    const [dateValue, setDateValue] = useState<Date | undefined>(originalDateValue);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      const nextDateValue = dateValue?.toISOString();

      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        nextDateValue,
      });
      onClose();
    }, [assetIds, boardIds, dateValue, field, onClose, onSave]);

    return (
      <TransactionModal
        primaryCTA={{
          children: 'Save',
          onClick: onConfirm,
          isLoading: isSubmitting,
          disabled: dateValue === originalDateValue,
        }}
        secondaryCTA={{ children: 'Cancel', onClick: onClose }}
        variant="modal-large"
        modalLabel={`Edit ${field.name}`}
        onDismiss={onClose}
      >
        <p className="mb-6 text-16">
          Select a date to apply to the {pluralize('item', assetIds.length + boardIds.length, true)} you’ve selected.
        </p>
        <DateInput
          selected={dateValue}
          placeholderText="Add a date"
          onChange={setDateValue}
          id={field.id}
          label={field.name}
          name={field.name}
          tx={{ width: '100%' }}
        />
      </TransactionModal>
    );
  },
);
BulkEditDateValueCustomFieldModal.displayName = 'BulkEditDateValueCustomFieldModal';
