import { AssetSearchFilters } from '@air/api/types';
import { useMemo } from 'react';

import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';
import { useSearchParams } from '~/hooks/search/useSearchParams';
import { useFiltersContext } from '~/providers/FiltersProvider';

export const useClipsFilters = () => {
  const { isFlattenedView } = useIsFlattenedView();
  const { hasSearchParams } = useSearchParams();
  const { filters: userDefinedFilters } = useFiltersContext();

  const {
    uploadedDateParam,
    updatedDateParam,
    takenDateParam,
    sourcesParam,
    tagsParam,
    bookmarkedParam,
    uploadersParam,
    createdDateParam,
    customFieldsParam,
    colorsParam,
    boardParam,
    extensionsParam,
    isOnBoardsParam,
    hasVersionsParam,
    hasOpenCommentsParam,
    untaggedParam,
    libraryParam,
    onlyInLibrariesParam,
    importedKeywordsParam,
    copyrightsParam,
    creatorsParam,
    camerasParam,
    videoFrameRatesParam,
    locationParam,
    videoAspectRatiosParam,
    audioCodingsParam,
    audioSampleRatesParam,
  } = useFilterParams();

  const filtersParams: AssetSearchFilters = useMemo(() => {
    const filtersParams: AssetSearchFilters = {};

    if (boardParam) {
      const hasAnyFilterApplied = Object.keys(userDefinedFilters).length > 0;
      if (isFlattenedView || hasSearchParams || hasAnyFilterApplied) {
        filtersParams.boardTree = { is: boardParam?.is };
      } else {
        filtersParams.board = boardParam;
      }
    }

    if (tagsParam) {
      filtersParams.tag = tagsParam;
    }

    if (sourcesParam) {
      filtersParams.source = sourcesParam;
    }

    if (uploadersParam) {
      filtersParams.member = uploadersParam;
    }

    if (colorsParam) {
      filtersParams.color = colorsParam;
    }

    if (bookmarkedParam) {
      filtersParams.bookmarked = bookmarkedParam;
    }

    if (createdDateParam) {
      filtersParams.recorded = createdDateParam;
    }

    if (updatedDateParam) {
      filtersParams.updated = updatedDateParam;
    }

    if (uploadedDateParam) {
      filtersParams.created = uploadedDateParam;
    }

    if (takenDateParam) {
      filtersParams.dateTaken = takenDateParam;
    }

    if (customFieldsParam.length) {
      filtersParams.customFields = customFieldsParam;
    }

    if (extensionsParam) {
      filtersParams.extension = extensionsParam;
    }

    if (isOnBoardsParam) {
      filtersParams.isOnBoards = isOnBoardsParam;
    }

    if (hasOpenCommentsParam) {
      filtersParams.hasOpenComments = hasOpenCommentsParam;
    }

    if (hasVersionsParam) {
      filtersParams.hasMultipleVersions = hasVersionsParam;
    }

    if (untaggedParam) {
      filtersParams.untagged = untaggedParam;
    }

    if (libraryParam) {
      filtersParams.library = libraryParam;
    }

    if (onlyInLibrariesParam) {
      filtersParams.onlyInLibraries = onlyInLibrariesParam;
    }

    if (locationParam) {
      filtersParams.location = locationParam;
    }

    if (creatorsParam) {
      filtersParams.creator = creatorsParam;
    }

    if (importedKeywordsParam) {
      filtersParams.keyword = importedKeywordsParam;
    }

    if (copyrightsParam) {
      filtersParams.copyright = copyrightsParam;
    }

    if (camerasParam) {
      filtersParams.camera = camerasParam;
    }

    if (videoFrameRatesParam) {
      filtersParams.videoFrameRate = videoFrameRatesParam;
    }

    if (videoAspectRatiosParam) {
      filtersParams.videoAspectRatio = videoAspectRatiosParam;
    }

    if (audioCodingsParam) {
      filtersParams.audioCoding = audioCodingsParam;
    }

    if (audioSampleRatesParam) {
      filtersParams.audioSampleRate = audioSampleRatesParam;
    }

    return filtersParams;
  }, [
    boardParam,
    tagsParam,
    sourcesParam,
    uploadersParam,
    colorsParam,
    bookmarkedParam,
    createdDateParam,
    updatedDateParam,
    uploadedDateParam,
    takenDateParam,
    customFieldsParam,
    extensionsParam,
    isOnBoardsParam,
    hasOpenCommentsParam,
    untaggedParam,
    hasVersionsParam,
    libraryParam,
    onlyInLibrariesParam,
    locationParam,
    creatorsParam,
    importedKeywordsParam,
    copyrightsParam,
    camerasParam,
    videoFrameRatesParam,
    videoAspectRatiosParam,
    audioCodingsParam,
    audioSampleRatesParam,
    userDefinedFilters,
    isFlattenedView,
    hasSearchParams,
  ]);

  return {
    filtersParams,
  };
};
