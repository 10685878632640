import { Close as CloseIcon, EllipsisVertical as EllipsisVerticalIcon } from '@air/next-icons';
import { DropdownMenu, type DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import { animated, config, useSpring } from '@react-spring/web';
import pluralize from 'pluralize';
import React, { SyntheticEvent, useState } from 'react';

import { SELECTION_ACTION_BAR_ID } from '~/constants/selectionActionBar';
import {
  SELECTION_ACTION_BAR,
  SELECTION_ACTION_BAR_CLEAR_BTN,
  SELECTION_ACTION_BAR_ITEMS_SELECTED,
} from '~/constants/testIDs';

import { SelectionActionBarIconButton } from './SelectionActionBarIconButton';
import { SelectionActionBarLink } from './SelectionActionBarLink';

export interface SelectionActionBarComponentProps {
  menuOptions: DropdownMenuOption[];
  numberOfSelectedItems: number;
  onSelectAll?: () => void;
  onClearAll: () => void;
  itemLabel?: string;
  baseMenuItemRenderer?: (item: DropdownMenuOption) => React.ReactNode;
}

const iconMenuRenderer = (item: DropdownMenuOption) => {
  if (item.type === 'item' || item.type === 'sub') {
    return <SelectionActionBarIconButton key={item.id} {...item} />;
  }

  return null;
};

/**
 * Displays as a fixed and centered panel on the bottom of the screen
 * Will show the first 4 menu options and move the remaining into an ellipsis menu
 */
export const SelectionActionBarComponent = ({
  menuOptions,
  numberOfSelectedItems,
  onClearAll,
  onSelectAll,
  itemLabel = 'item',
  baseMenuItemRenderer = iconMenuRenderer,
}: SelectionActionBarComponentProps) => {
  const [actionsContainerRef, setActionsContainerRef] = useState<HTMLDivElement | null>(null);
  const { isAboveSmallScreen } = useBreakpointsContext();
  const actionsContainerWidth = actionsContainerRef?.clientWidth || 160;
  const itemPluralText = pluralize(itemLabel, numberOfSelectedItems);
  const itemCountText = !isAboveSmallScreen ? ` selected` : ` ${itemPluralText} selected`;
  const maxIconsAllowed = !isAboveSmallScreen ? 0 : 4;
  const maxIconCount = actionsContainerWidth / 40;
  const baseIconIndex = maxIconCount > maxIconsAllowed ? maxIconsAllowed : maxIconCount;

  const baseMenuItems = menuOptions.filter((item) => item.type !== 'separator').slice(0, baseIconIndex);

  const dropdownMenuItems = menuOptions.slice(baseIconIndex);

  const [springs] = useSpring(() => ({
    from: {
      opacity: 0,
      y: 16,
    },
    to: {
      opacity: 1,
      y: 0,
    },
    config: config.stiff,
  }));

  return (
    <animated.li
      className="pointer-events-auto flex h-14 w-full max-w-full items-center rounded-none bg-pigeon-900 px-3 shadow-popover sm:h-16 sm:w-[calc(100%-16px)] sm:max-w-[720px] sm:rounded sm:pl-8 sm:pr-6"
      style={springs}
      data-testid={SELECTION_ACTION_BAR}
      id={SELECTION_ACTION_BAR_ID}
    >
      <IconButton
        appearance="ghost"
        className="mr-3 text-pigeon-50 hover:bg-pigeon-700 active:bg-pigeon-800"
        color="grey"
        data-testid={SELECTION_ACTION_BAR_CLEAR_BTN}
        icon={CloseIcon}
        label="Clear all"
        onClick={onClearAll}
        size="medium"
      />
      <div className="flex items-center" data-testid={SELECTION_ACTION_BAR_ITEMS_SELECTED}>
        <div className="mr-2 text-14 text-white">
          <b>{numberOfSelectedItems}</b>
          {itemCountText}
        </div>
        {onSelectAll && <SelectionActionBarLink onClick={onSelectAll}>Select all</SelectionActionBarLink>}
      </div>
      <div className="flex grow items-center justify-end gap-3" ref={(newRef) => setActionsContainerRef(newRef)}>
        <div className="flex gap-3">{baseMenuItems.map((item) => item && baseMenuItemRenderer(item))}</div>
        {!!dropdownMenuItems.length && (
          <DropdownMenu
            sideOffset={16}
            trigger={
              <IconButton
                appearance="ghost"
                className="text-pigeon-50 hover:bg-pigeon-700 active:bg-pigeon-800"
                color="grey"
                data-testid="SELECTION_ACTION_BAR_MORE_ACTIONS"
                label="More actions"
                onClick={(event: SyntheticEvent) => event.stopPropagation()}
                size="large"
              >
                <EllipsisVerticalIcon className="h-4 w-4" />
              </IconButton>
            }
          >
            {renderDropdownItems({ options: dropdownMenuItems })}
          </DropdownMenu>
        )}
      </div>
    </animated.li>
  );
};
