import { memo, useCallback } from 'react';

import { SelectProps } from '~/components/Zephyr/Select/Select';
import { SingleSelect } from '~/components/Zephyr/Select/SingleSelect';
import { FilterLogicType } from '~/store/filters/types';

export interface FilterLogicSelectProps {
  selectedLogic: FilterLogicType;
  onLogicChange: (selected: FilterLogicType) => void;
}

interface LogicOption {
  value: FilterLogicType;
  label: string;
}

const logicOptions: LogicOption[] = [
  { value: 'and', label: 'Contains' },
  { value: 'or', label: 'Contains one of...' },
  { value: 'not', label: 'Does not contain' },
];

export const FilterLogicSelect = memo(({ selectedLogic, onLogicChange }: FilterLogicSelectProps) => {
  const onSelectionChange: SelectProps<LogicOption>['onSelectionChange'] = useCallback(
    ({ chips }) => {
      onLogicChange(chips[chips.length - 1].value);
    },
    [onLogicChange],
  );

  return (
    <SingleSelect
      options={logicOptions}
      selectedOption={logicOptions.find((option) => option.value === selectedLogic)!}
      onSelectionChange={onSelectionChange}
      placeholder=""
      variant="field-input-smol"
      spacingStyles={{ mb: 16 }}
    />
  );
});

FilterLogicSelect.displayName = 'FilterLogicSelect';
