import { Color } from '@air/api/types';
import { memo } from 'react';

import { CheckboxButton } from '~/components/Zephyr/CheckboxButton';
import { COLOR_FILTER_CHECKBOX } from '~/constants/testIDs';

export interface ColorFilterProps {
  colors: Color[];
  onClick: (color: Color) => (isSelected: boolean) => void;
  selectedColors: string[];
}

export const ColorFilter = memo(({ colors, onClick, selectedColors }: ColorFilterProps) => {
  return (
    <div className="flex flex-wrap gap-1">
      {colors.map((color) => (
        <CheckboxButton
          data-testid={`${COLOR_FILTER_CHECKBOX}-${color.name}`}
          label={color.name}
          key={color.hex}
          isSelected={selectedColors.includes(color.hex)}
          onClick={onClick(color)}
          color={color.hex}
          size={20}
        />
      ))}
    </div>
  );
});

ColorFilter.displayName = 'ColorFilter';
