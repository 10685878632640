import { ReactDatePickerProps } from 'react-datepicker';

import { DateInput } from '~/components/Zephyr/DateInput/DateInput';

export interface DateRangeInputProps extends Omit<ReactDatePickerProps, 'onChange' | 'selectsRange'> {
  onStartDateChange: (date?: Date) => void;
  onEndDateChange: (date?: Date) => void;
}

export const DateRangeInput = ({ startDate, endDate, onEndDateChange, onStartDateChange }: DateRangeInputProps) => {
  return (
    <div className="flex items-center">
      <DateInput
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        selectsStart
        label="Start date"
        name="startDate"
        onChange={onStartDateChange}
      />
      <div className="mx-2.5 h-0.5 w-3 rounded-sm bg-pigeon-400" />
      <DateInput
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        selectsEnd
        label="End date"
        name="endDate"
        onChange={onEndDateChange}
      />
    </div>
  );
};
