import { memo, useCallback, useMemo } from 'react';

import { SelectChipOptionContainer } from '~/components/Zephyr/Select/components/SelectChipOptionContainer';
import { Select, SelectProps } from '~/components/Zephyr/Select/Select';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';
import { isKnownClipSource, SOURCE_MAP } from '~/constants/sourceMap';

interface SourcesSelectProps
  extends Omit<SelectProps, 'placeholder' | 'options' | 'selectedOptions' | 'onSelectionChange'> {
  selectedValues: string[];
  onSelectionChange: (newString: string[]) => void;
  sources: string[];
}

const sourceToOption = (source: string): DefaultChipType => ({
  value: source,
  label: isKnownClipSource(source) ? SOURCE_MAP[source] : source,
  borderRadius: 4,
});

export const SourcesSelect = memo(({ sources, selectedValues, onSelectionChange, ...props }: SourcesSelectProps) => {
  const options = useMemo<DefaultChipType[]>(() => sources.map((s) => sourceToOption(s)), [sources]);
  const selectedOptions = useMemo<DefaultChipType[]>(
    () => selectedValues.map((s) => sourceToOption(s)),
    [selectedValues],
  );

  const onValuesChange: SelectProps<DefaultChipType>['onSelectionChange'] = useCallback(
    ({ chips }) => {
      onSelectionChange(chips.map((chip) => chip.value));
    },
    [onSelectionChange],
  );

  const listItemRenderer = useCallback<Required<SelectProps>['listItemRenderer']>(
    (option) => (
      <SelectChipOptionContainer className="flex justify-between py-2">
        <p className="text-14 text-grey-12">{option.label}</p>
      </SelectChipOptionContainer>
    ),
    [],
  );

  return (
    <Select
      data-testid="SOURCES_SELECT"
      placeholder="Search upload sources"
      listItemRenderer={listItemRenderer}
      maxDropdownHeight={300}
      options={options}
      renderAsInput
      isSearchable
      onSelectionChange={onValuesChange}
      selectedOptions={selectedOptions}
      alwaysShowClearItemButton
      isClearable
      isSticky
      variant="field-input-smol"
      {...props}
    />
  );
});

SourcesSelect.displayName = 'SourcesSelect';
