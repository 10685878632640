import { AirActionTrackingLocation } from '@air/analytics';
import { Clip } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { TransactionModal } from '@air/zephyr';
import { differenceBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useState } from 'react';

import { InternalLink } from '~/components/Link';
import { PrivateTagsSelect } from '~/components/TagsSelect/PrivateTagsSelect';
import { TagBase } from '~/components/TagsSelect/utils';
import { Routes } from '~/constants/routes';
import { EDIT_CLIPS_TAGS_MODAL } from '~/constants/testIDs';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useAddTagsToClips } from '~/swr-hooks/tags/useAddTagsToClips';
import { useRemoveTagsFromClips } from '~/swr-hooks/tags/useRemoveTagsFromClips';
import { insertIf } from '~/utils/insertIf';
import {
  useRemoveAssetsWithoutTagsFromFilteredViews,
  useUpdateAssetsInAllViews,
} from '~/utils/mutateUtils/GalleryAssets';
import { getBoardIdFromPath } from '~/utils/PathUtils';

export interface EditClipsTagsModalProps {
  clipIds: Clip['id'][];
  trackLocation: AirActionTrackingLocation;
  initialTags: TagBase[];
}

export const EditClipsTagsModal = ({
  onClose,
  clipIds,
  trackLocation,
  initialTags,
}: AirModalProps<EditClipsTagsModalProps>) => {
  const { showToast } = useToasts();
  const { addTagsToClips } = useAddTagsToClips();
  const { removeTagsFromClips } = useRemoveTagsFromClips();
  const { updateAssetsInAllViews } = useUpdateAssetsInAllViews();
  const { removeAssetsWithoutTagsFromFilteredViews } = useRemoveAssetsWithoutTagsFromFilteredViews();
  const [saving, setSaving] = useState(false);
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });

  const [editedTags, setEditedTags] = useState<TagBase[]>(initialTags);

  const handleSubmit = async () => {
    setSaving(true);

    try {
      const tagsToAdd = differenceBy(editedTags, initialTags, 'id');
      const tagsToRemove = differenceBy(initialTags, editedTags, 'id');

      await Promise.all([
        ...insertIf(tagsToAdd.length > 0, () => [addTagsToClips({ tags: tagsToAdd, clipIds, trackLocation })]),
        ...insertIf(tagsToRemove.length > 0, () => [
          removeTagsFromClips({ tags: tagsToRemove, clipIds, trackLocation }),
        ]),
      ]);

      const tagsToRemoveIds = tagsToRemove.map(({ id }) => id);

      updateAssetsInAllViews({
        assetIdentifiers: clipIds,
        identifier: 'id',
        boardId: getBoardIdFromPath(window.location.pathname),
        updateFn: (clip) => {
          const filteredClipTags = clip.tags.filter(({ id }) => !tagsToRemoveIds.includes(id));
          clip.tags = [
            ...filteredClipTags,
            ...tagsToAdd
              .filter((tag) => !clip.tags.find((t) => t.id === tag.id))
              .map((tag) => ({ ...tag, isDemo: false })),
          ];
          return clip;
        },
      });

      removeAssetsWithoutTagsFromFilteredViews({
        clipIds,
        changedTags: [...tagsToAdd, ...tagsToRemove],
      });

      showToast(`Tags saved on ${pluralize('asset', clipIds.length, true)}`);
      onClose();
    } catch (_error) {
      setSaving(false);
      showToast('An error occurred - please try again later');
    }
  };

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  return (
    <TransactionModal
      onDismiss={onClose}
      modalLabel="Edit tags"
      primaryCTA={{
        isLoading: saving,
        disabled: editedTags.some(({ id }) => !id),
        onClick: handleSubmit,
        children: 'Save',
      }}
      secondaryCTA={{
        onClick: onClose,
        children: 'Cancel',
      }}
      data-testid={EDIT_CLIPS_TAGS_MODAL}
      modalDescription={
        <>
          Add or remove tags from the {pluralize('item', clipIds.length)} you’ve selected. Manage your workspace’s tags
          in{' '}
          <InternalLink href={Routes.workspaceAdmin.tags} target="_blank" tx={{ fontWeight: 'regular' }}>
            settings
          </InternalLink>
          .
        </>
      }
    >
      <div className="mt-7">
        <PrivateTagsSelect selectedTags={editedTags} onSelectedTagsChanged={setEditedTags} canCreateNewTags />
      </div>
    </TransactionModal>
  );
};
