import { Box, BoxProps } from '@air/zephyr';
import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import { useTheme } from 'styled-components';

import { getMinTableContentWidth } from '~/components/TableView/types';
import { TABLE_CELL_H_PADDING, TABLE_CELL_V_PADDING, TableCell } from '~/components/TableView/ui';
import { activeTableColumnsSelector } from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export type TableViewRowProps = BoxProps;

export const TABLE_ROW_LEFT_PADDING = 16;

export const DesktopTableRowContainer = memo(({ tx, ...props }: TableViewRowProps) => {
  const theme = useTheme();

  const activeTableColumns = useAirSelector(activeTableColumnsSelector);
  return (
    <Box
      tx={{
        flex: 1,
        minWidth: getMinTableContentWidth(activeTableColumns),

        ml: `-${TABLE_ROW_LEFT_PADDING}px`,

        [`${TableCell}`]: {
          p: `${TABLE_CELL_V_PADDING}px ${TABLE_CELL_H_PADDING}px`,
        },

        '.tableRow': {
          borderRadius: 4,
          mr: -16,

          '.js-focus-visible &[data-focus-visible-added]': {
            outline: 'none',
            backgroundColor: 'white',
            boxShadow: `0 0 0 3px ${theme.colors.macaw200}`,
          },
        },

        ...tx,
      }}
      {...props}
    />
  );
}, isEqual);

DesktopTableRowContainer.displayName = 'TableViewRow';
