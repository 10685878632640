import { Box } from '@air/zephyr';
import styled, { css } from 'styled-components';

export const PageContainer = styled(Box)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const EllipsizedTextCss = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
