import { FiltersTrackingLocation } from '@air/analytics';
import { Plus } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import classNames from 'classnames';
import { ComponentType, memo, ReactNode, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { ColorFilterCardProps } from '~/components/Filters/ColorFilter/ColorFilterCard';
import { PublicCustomFieldFilterCardProps } from '~/components/Filters/CustomFieldFilter/PublicCustomFieldFilterCard';
import { CreatedDateFilterCard } from '~/components/Filters/DateFilter/CreatedDateFilterCard';
import { ModifiedDateFilterCard } from '~/components/Filters/DateFilter/ModifiedDateFilterCard';
import { TakenDateFilterCard } from '~/components/Filters/DateFilter/TakenDateFilterCard';
import { UploadedDateFilterCard } from '~/components/Filters/DateFilter/UploadedDateFilterCard';
import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { SelectedFiltersContainer } from '~/components/Filters/FiltersDropdown/ui';
import { useFiltersDropdownController } from '~/components/Filters/FiltersDropdown/useFiltersDropdownController';
import { OtherFiltersCard } from '~/components/Filters/OtherFilters/OtherFiltersCard';
import { TagsFilterCardProps } from '~/components/Filters/TagsFilter/TagsFilterCard';
import { TypeFilterCard } from '~/components/Filters/TypeFilter/TypeFilterCard';
import { ItemTypeFilter } from '~/components/Filters/types';
import { UploaderFilterCardProps } from '~/components/Filters/UploaderFilter/UploaderFilterCard';
import { FiltersMenu } from '~/components/FiltersMenu/FiltersMenu';
import {
  CustomFieldFilterType,
  FilterSelectType,
  FilterType,
  GetFiltersMenuOptionsParams,
} from '~/components/FiltersMenu/types';
import { useFiltersMenuOptions } from '~/components/FiltersMenu/useFiltersMenuOptions';
import { ADD_FILTER_BUTTON, APPLY_FILTERS_BUTTON, CLEAR_ALL_FILTERS_BUTTON } from '~/constants/testIDs';
import { addFilterAction } from '~/store/filters/actions';
import { OtherFiltersType, SelectedFilter } from '~/store/filters/types';

export interface FiltersDropdownProps {
  title: string;
  onApplyClick: () => void;
  availableTypes?: ItemTypeFilter[];
  otherFilters?: OtherFiltersType[];
  availableFilters: FilterType[];
  customFields?: CustomFieldFilterType[];
  VideoFrameRateFilterCard?: ComponentType<BaseFilterCardProps>;
  VideoAspectRatioFilterCard?: ComponentType<BaseFilterCardProps>;
  AudioCodingFilterCard?: ComponentType<BaseFilterCardProps>;
  AudioSampleRateFilterCard?: ComponentType<BaseFilterCardProps>;
  ColorFilterCard?: ComponentType<Omit<ColorFilterCardProps, 'colors'>>;
  TagsFilterCard?: ComponentType<Omit<TagsFilterCardProps, 'TagsSelect'>>;
  CameraFilterCard?: ComponentType<BaseFilterCardProps>;
  SourceFilterCard?: ComponentType<BaseFilterCardProps>;
  CountryFilterCard?: ComponentType<BaseFilterCardProps>;
  CityFilterCard?: ComponentType<BaseFilterCardProps>;
  StateFilterCard?: ComponentType<BaseFilterCardProps>;
  ExtensionFilterCard?: ComponentType<BaseFilterCardProps>;
  UploaderFilterCard?: ComponentType<Omit<UploaderFilterCardProps, 'uploaders'>>;
  ImportedKeywordsFilterCard?: ComponentType<BaseFilterCardProps>;
  CustomFieldFilterCard?: ComponentType<Omit<PublicCustomFieldFilterCardProps, 'CustomFieldValuesFilterSelect'>>;
  CopyrightFilterCard?: ComponentType<BaseFilterCardProps>;
  CreatorFilterCard?: ComponentType<BaseFilterCardProps>;
  trackLocation: FiltersTrackingLocation;
  secondaryCTA?: ReactNode;
  isFilterChanged?: boolean;
}

export const FiltersDropdown = memo(
  ({
    title,
    customFields = [],
    onApplyClick,
    ColorFilterCard,
    TagsFilterCard,
    CameraFilterCard,
    SourceFilterCard,
    ExtensionFilterCard,
    UploaderFilterCard,
    CustomFieldFilterCard,
    CopyrightFilterCard,
    CreatorFilterCard,
    availableTypes = ['boards', 'assets', 'files'],
    availableFilters,
    otherFilters,
    secondaryCTA,
    trackLocation,
    isFilterChanged,
    ImportedKeywordsFilterCard,
    CountryFilterCard,
    CityFilterCard,
    StateFilterCard,
    VideoFrameRateFilterCard,
    VideoAspectRatioFilterCard,
    AudioCodingFilterCard,
    AudioSampleRateFilterCard,
  }: FiltersDropdownProps) => {
    const dispatch = useDispatch();
    const filtersContainerRef = useRef<HTMLDivElement>(null);
    const { getFiltersMenuOptions } = useFiltersMenuOptions();
    const { isAboveMediumScreen } = useBreakpointsContext();

    const { applySelectedFilters, clearSelectedFilters, selectedFilters } = useFiltersDropdownController({
      availableFilters,
      doAfterApply: onApplyClick,
      customFields,
      trackLocation,
    });

    const onFilterSelect: FilterSelectType = useCallback(
      async (type, customField) => {
        dispatch(addFilterAction({ filter: { type, customField } }));
        filtersContainerRef.current?.scrollTo({ top: filtersContainerRef.current?.scrollHeight, behavior: 'smooth' });
      },
      [dispatch],
    );

    const getFiltersOptions = useCallback(
      (onSelect: GetFiltersMenuOptionsParams['onSelect']) =>
        getFiltersMenuOptions({
          availableFilters,
          selectedFilters,
          onSelect,
          customFields,
        }),
      [availableFilters, customFields, getFiltersMenuOptions, selectedFilters],
    );

    const addButtonFilters = useMemo(() => getFiltersOptions(onFilterSelect), [getFiltersOptions, onFilterSelect]);

    const cardProps = useMemo(
      (): Pick<BaseFilterCardProps, 'getFiltersOptions' | 'trackLocation'> => ({
        getFiltersOptions,
        trackLocation,
      }),
      [getFiltersOptions, trackLocation],
    );

    const renderFilterCard = useCallback(
      (filter: SelectedFilter) => {
        switch (filter.type) {
          case 'type':
            return <TypeFilterCard availableTypes={availableTypes} key={filter.type} {...cardProps} />;
          case 'color':
            return ColorFilterCard ? <ColorFilterCard key={filter.type} {...cardProps} /> : null;
          case 'tags':
            return TagsFilterCard ? <TagsFilterCard key={filter.type} {...cardProps} /> : null;
          case 'other':
            return <OtherFiltersCard key={filter.type} otherFilters={otherFilters} {...cardProps} />;
          case 'dateCreated':
            return <CreatedDateFilterCard key={filter.type} {...cardProps} />;
          case 'dateModified':
            return <ModifiedDateFilterCard key={filter.type} {...cardProps} />;
          case 'dateUploaded':
            return <UploadedDateFilterCard key={filter.type} {...cardProps} />;
          case 'dateTaken':
            return <TakenDateFilterCard key={filter.type} {...cardProps} />;
          case 'source':
            return SourceFilterCard ? <SourceFilterCard key={filter.type} {...cardProps} /> : null;
          case 'extension':
            return ExtensionFilterCard ? <ExtensionFilterCard key={filter.type} {...cardProps} /> : null;
          case 'creator':
            return CreatorFilterCard ? <CreatorFilterCard key={filter.type} {...cardProps} /> : null;
          case 'uploader':
            return UploaderFilterCard ? <UploaderFilterCard key={filter.type} {...cardProps} /> : null;
          case 'copyright':
            return CopyrightFilterCard ? <CopyrightFilterCard key={filter.type} {...cardProps} /> : null;
          case 'importedKeywords':
            return ImportedKeywordsFilterCard ? <ImportedKeywordsFilterCard key={filter.type} {...cardProps} /> : null;
          case 'camera':
            return CameraFilterCard ? <CameraFilterCard key={filter.type} {...cardProps} /> : null;
          case 'customField':
            return filter.customField && CustomFieldFilterCard ? (
              <CustomFieldFilterCard key={filter.customField.id} customField={filter.customField} {...cardProps} />
            ) : null;
          case 'country':
            return CountryFilterCard ? <CountryFilterCard key={filter.type} {...cardProps} /> : null;
          case 'city':
            return CityFilterCard ? <CityFilterCard key={filter.type} {...cardProps} /> : null;
          case 'state':
            return StateFilterCard ? <StateFilterCard key={filter.type} {...cardProps} /> : null;
          case 'videoFrameRate':
            return VideoFrameRateFilterCard ? <VideoFrameRateFilterCard key={filter.type} {...cardProps} /> : null;
          case 'videoAspectRatio':
            return VideoAspectRatioFilterCard ? <VideoAspectRatioFilterCard key={filter.type} {...cardProps} /> : null;
          case 'audioCoding':
            return AudioCodingFilterCard ? <AudioCodingFilterCard key={filter.type} {...cardProps} /> : null;
          case 'audioSampleRate':
            return AudioSampleRateFilterCard ? <AudioSampleRateFilterCard key={filter.type} {...cardProps} /> : null;
          default:
            return 'TBD';
        }
      },
      [
        AudioCodingFilterCard,
        AudioSampleRateFilterCard,
        CameraFilterCard,
        availableTypes,
        cardProps,
        ColorFilterCard,
        TagsFilterCard,
        otherFilters,
        SourceFilterCard,
        ExtensionFilterCard,
        CreatorFilterCard,
        UploaderFilterCard,
        CopyrightFilterCard,
        ImportedKeywordsFilterCard,
        CustomFieldFilterCard,
        CountryFilterCard,
        CityFilterCard,
        StateFilterCard,
        VideoFrameRateFilterCard,
        VideoAspectRatioFilterCard,
      ],
    );

    const hasAnyFilters = selectedFilters.length > 0;

    const trigger = useMemo(
      () => (
        <Button
          appearance="filled"
          color="grey"
          data-testid={ADD_FILTER_BUTTON}
          prefix={<Plus className="h-4 w-4" />}
          size="medium"
        >
          Add filter
        </Button>
      ),
      [],
    );

    const showFiltersContent = hasAnyFilters || !isAboveMediumScreen;

    return (
      <>
        <div
          data-testid="FILTERS_DROPDOWN_TITLE"
          className="border-b border-b-grey-5 p-4 text-12 font-bold uppercase tracking-wider text-grey-10"
        >
          {title}
        </div>
        {showFiltersContent && (
          <SelectedFiltersContainer className="flex-1 overflow-auto bg-grey-3 p-2" ref={filtersContainerRef}>
            {selectedFilters.map((filter) => renderFilterCard(filter))}
          </SelectedFiltersContainer>
        )}
        <div className={classNames('flex justify-between p-3', showFiltersContent ? 'border-t border-t-grey-5' : '')}>
          <FiltersMenu options={addButtonFilters} trigger={trigger} />
          <div className="flex gap-2">
            {secondaryCTA || (
              <Button
                appearance="ghost"
                className="mr-3"
                color="grey"
                data-testid={CLEAR_ALL_FILTERS_BUTTON}
                disabled={!selectedFilters.length}
                size="medium"
                onClick={clearSelectedFilters}
              >
                Clear all
              </Button>
            )}
            <Button
              appearance="filled"
              color="blue"
              data-testid={APPLY_FILTERS_BUTTON}
              disabled={!isFilterChanged}
              size="medium"
              onClick={applySelectedFilters}
            >
              Apply
            </Button>
          </div>
        </div>
      </>
    );
  },
);

FiltersDropdown.displayName = 'FiltersDropdown';
