import { keyBy } from 'lodash';
import { memo, useCallback, useMemo } from 'react';

import { Select, SelectProps } from '~/components/Zephyr/Select/Select';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';
import { CUSTOM_FIELD_VALUE_SELECT } from '~/constants/testIDs';

import { convertCustomFieldValueToFilterOption, CustomFieldValueFilterBase } from './utils';

export interface CustomFieldValuesFilterSelectProps
  extends Omit<SelectProps<DefaultChipType>, 'options' | 'selectedOptions' | 'onSelectionChange'> {
  selectedValues: CustomFieldValueFilterBase[];
  onSelectedValuesChanged: (values: CustomFieldValueFilterBase[]) => void;
  values: CustomFieldValueFilterBase[];
}

export const CustomFieldValuesFilterSelect = memo(
  ({
    placeholder = 'Select field values',
    onSelectedValuesChanged,
    selectedValues,
    values,
    ...props
  }: CustomFieldValuesFilterSelectProps) => {
    const options = useMemo(() => values.map((value) => convertCustomFieldValueToFilterOption(value)), [values]);
    const selectedOptions = useMemo(
      () => selectedValues.map((value) => convertCustomFieldValueToFilterOption(value)),
      [selectedValues],
    );

    const onSelectionChange: SelectProps<DefaultChipType>['onSelectionChange'] = useCallback(
      ({ chips }) => {
        const valuesById = keyBy(values, 'id');
        const selected = chips.map(({ value }) => valuesById[value]);
        onSelectedValuesChanged(selected);
      },
      [onSelectedValuesChanged, values],
    );

    return (
      <Select
        data-testid={CUSTOM_FIELD_VALUE_SELECT}
        options={options}
        selectedOptions={selectedOptions}
        isSearchable
        maxDropdownHeight={300}
        onSelectionChange={onSelectionChange}
        placeholder={placeholder}
        renderAsInput
        {...props}
      />
    );
  },
);

CustomFieldValuesFilterSelect.displayName = 'CustomFieldValuesFilterSelect';
