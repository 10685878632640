import { ClipSource } from '@air/api/types';

export const SOURCE_MAP: {
  [key in ClipSource]: string;
} = {
  [ClipSource.iOSPhotosLibrary]: 'iOS',
  [ClipSource.app]: 'App',
  [ClipSource.web]: 'Web',
  [ClipSource.desktop]: 'Air Flow',
  [ClipSource.desktopSync]: 'Air Flow',
  [ClipSource.dropbox]: 'Dropbox',
  [ClipSource.transferred]: 'Transferred',
  [ClipSource.instagram]: 'Instagram',
  [ClipSource.googleDrive]: 'Google Drive',
  [ClipSource.vimeo]: 'Vimeo',
  [ClipSource.smugmug]: 'SmugMug',
  [ClipSource.box]: 'Box',
  [ClipSource.frameio]: 'Frame.io',
  [ClipSource.flickr]: 'Flickr',
  [ClipSource.piction]: 'Piction',
  [ClipSource.sharepoint]: 'SharePoint',
  [ClipSource.sharePoint]: 'SharePoint',
  [ClipSource.quickconnect]: 'QuickConnect',
  [ClipSource.s3]: 'Amazon S3',
  [ClipSource.lightroom]: 'Lightroom',
  [ClipSource.egnyte]: 'Egnyte',
  [ClipSource.brandfolder]: 'Brandfolder',
  [ClipSource.widen]: 'Widen',
  [ClipSource.bynder]: 'Bynder',
  [ClipSource.jsonMetadata]: 'JSON Metadata',
  [ClipSource.publicApi]: 'Public API',
  [ClipSource.canto]: 'Canto',
  [ClipSource.openAsset]: 'OpenAsset',
  [ClipSource.photoshelter]: 'PhotoShelter',
  [ClipSource.webdam]: 'WebDAM',
  [ClipSource.csv]: 'CSV',
};

const SourceKeys = Object.keys(SOURCE_MAP);

export const isKnownClipSource = (source: string): source is ClipSource => SourceKeys.includes(source);
