import { Tooltip } from '@air/primitive-tooltip';
import { format } from 'date-fns';
import { memo, useMemo, useState } from 'react';

import { CustomField } from '~/components/CustomField';
import { CustomFieldListWithOverflow } from '~/components/CustomFields/CustomFieldListWithOverflow';
import { customFieldIconMap } from '~/constants/customFieldIconMap';
import { WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT } from '~/constants/WorkspaceSpacing';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';
import { parseAirDateToISO } from '~/utils/DateUtils';

interface CustomFieldDisplayProps {
  cfValue: AssignedCustomFieldValue;
  shouldShowLabelInline?: boolean;
}

export const CustomFieldDisplay = memo(({ cfValue, shouldShowLabelInline }: CustomFieldDisplayProps) => {
  const Icon = customFieldIconMap[cfValue.type.title];
  const [fullRowRef, setFullRowRef] = useState<HTMLDivElement | null>(null);
  const [valueRef, setValueRef] = useState<HTMLDivElement | null>(null);

  const labelWidth = useMemo(() => {
    if (!shouldShowLabelInline) return 0;
    if (fullRowRef) {
      return Math.min(250, fullRowRef.clientWidth * 0.35 - 16);
    }
    return 0;
  }, [fullRowRef, shouldShowLabelInline]);

  const valueDisplay = useMemo(() => {
    if (cfValue.value) {
      return <CustomField field={cfValue.value} size="small" />;
    }
    if (cfValue.values) {
      return (
        <CustomFieldListWithOverflow
          size="small"
          customFields={[cfValue]}
          allowedWidth={(valueRef?.clientWidth || 200) - 4}
        />
      );
    }
    let text = cfValue.plainTextValue || '';
    if (cfValue.dateValue) {
      text = format(parseAirDateToISO(cfValue.dateValue), 'MMM dd, yyyy');
    }
    if (text) {
      return (
        <CustomField
          field={{
            id: cfValue.id,
            color: {
              name: 'light-grey',
            },
            value: text,
          }}
          size="small"
        />
      );
    }
    return <p className="ml-1 text-12 font-normal text-pigeon-400">None</p>;
  }, [cfValue, valueRef?.clientWidth]);

  return (
    <div
      className="flex w-full items-center gap-1 overflow-hidden py-0.5"
      style={{
        height: WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT,
      }}
      ref={setFullRowRef}
    >
      <Icon className="h-3 w-3 shrink-0 text-grey-9" />
      {shouldShowLabelInline ? (
        <div className="flex w-full items-center">
          <span className="truncate text-left text-12 font-medium text-pigeon-500" style={{ width: labelWidth }}>
            {cfValue.name}
          </span>
          <div className="flex flex-1" ref={setValueRef}>
            {valueDisplay}
          </div>
        </div>
      ) : (
        <div className="flex grow" ref={setValueRef}>
          <Tooltip label={cfValue.name} side="top" sideOffset={2}>
            <div className="inline-flex">{valueDisplay}</div>
          </Tooltip>
        </div>
      )}
    </div>
  );
});

CustomFieldDisplay.displayName = 'CustomFieldDisplay';
