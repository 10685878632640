import { useCallback, useSyncExternalStore } from 'react';
import { useSelector } from 'react-redux';

import { CardSize, CardSizeListener, CardSizeStore } from '~/classes/CardSizeStore';
import { useRouterSelector } from '~/hooks/useRouterSelector';
import { centralizedBoardLibraryIdSelector, CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';

const getServerSnapshot = (): CardSize => 'medium';

export const useCardSizePreference = () => {
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const pathname = useRouterSelector((state) => state.pathname);

  const key = boardId || libraryId || pathname || '';

  const getCardSize = useCallback(() => CardSizeStore.getInstance().getCardSize(key), [key]);
  const subscribe = useCallback(
    (callback: CardSizeListener) => CardSizeStore.getInstance().subscribe(key, callback),
    [key],
  );

  const updateCardSize = useCallback((value: CardSize) => CardSizeStore.getInstance().setCardSize(key, value), [key]);

  const cardSize = useSyncExternalStore(subscribe, getCardSize, getServerSnapshot);

  return { cardSize, updateCardSize };
};
