import { Box, BoxProps } from '@air/zephyr';

export const MobileTableRowContainer = ({ tx, ...props }: BoxProps) => (
  <Box
    tx={{
      display: 'flex',
      px: 12,
      borderRadius: 2,

      ...tx,
    }}
    {...props}
  />
);
