import { Box } from '@air/zephyr';
import { memo, useCallback } from 'react';

import { DateFilterTypeSelect } from '~/components/Filters/DateFilter/DateFilterTypeSelect';
import { DateRangeInput } from '~/components/Zephyr/DateInput/DateRangeInput';
import { DateRangeType } from '~/store/filters/types';

export interface DateFilterProps {
  selectedType: DateRangeType;
  startDate?: Date;
  endDate?: Date;
  onStartDateChange: (date?: Date) => void;
  onEndDateChange: (date?: Date) => void;
  onTypeChange: (dateType: DateRangeType) => void;
}

export const DateFilter = memo(
  ({ startDate, endDate, selectedType, onStartDateChange, onEndDateChange, onTypeChange }: DateFilterProps) => {
    const onDateFilterTypeChange = useCallback(
      (selectedType: DateRangeType) => {
        onStartDateChange();
        onEndDateChange();
        onTypeChange(selectedType);
      },
      [onEndDateChange, onStartDateChange, onTypeChange],
    );

    return (
      <Box tx={{ input: { fontSize: 12 } }}>
        <DateFilterTypeSelect selectedType={selectedType} onTypeChange={onDateFilterTypeChange} />
        {selectedType === 'custom-range' ? (
          <DateRangeInput
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        ) : null}
      </Box>
    );
  },
);

DateFilter.displayName = 'DateFilter';
