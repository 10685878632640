import { TransactionModal } from '@air/zephyr';
import { EditableText, EditableTextProps } from '@air/zephyr-editable-text';
import pluralize from 'pluralize';
import { memo, useCallback, useMemo, useState } from 'react';

import Linkify from '~/components/Linkify';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditPlainTextCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  onSave: (params: {
    assetIds: string[];
    boardIds: string[];
    baseCustomField: BulkEditCustomFieldModalProps['field'];
    nextPlainTextValue: string;
  }) => Promise<void>;
}

export const BulkEditPlainTextCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues,
    onClose,
    onSave,
  }: AirModalProps<BulkEditPlainTextCustomFieldModalProps>) => {
    const allHaveSameValue = useMemo(() => {
      const baseValue = initialValues[0]?.plainTextValue;
      return initialValues.every((cf) => cf.plainTextValue === baseValue);
    }, [initialValues]);

    const originalValue = allHaveSameValue ? initialValues[0]?.plainTextValue || '' : '';
    const [plainTextValue, setPlainTextValue] = useState<string>(originalValue);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const onSubmit: EditableTextProps['onSubmit'] = useCallback(
      (values) => {
        const nextValue = values['editable-text-value'];
        if (originalValue === nextValue) {
          setIsTouched(false);
        } else {
          setPlainTextValue(nextValue);
        }
      },
      [originalValue],
    );

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        nextPlainTextValue: plainTextValue,
      });
      onClose();
    }, [assetIds, boardIds, field, onClose, onSave, plainTextValue]);

    return (
      <TransactionModal
        primaryCTA={{
          children: 'Save',
          onClick: onConfirm,
          isLoading: isSubmitting,
          disabled: !isTouched,
        }}
        secondaryCTA={{ children: 'Cancel', onClick: onClose }}
        variant="modal-large"
        modalLabel={`Edit ${field.name}`}
        onDismiss={onClose}
      >
        <p className="mb-6 text-16">
          Enter a value below to apply to the {pluralize('item', assetIds.length + boardIds.length, true)} you’ve
          selected.
        </p>
        <EditableText
          formatValue={(value) => <Linkify>{value}</Linkify>}
          label={field.name}
          onValueChange={() => setIsTouched(true)}
          onSubmit={onSubmit}
          placeholder="None"
          tx={{
            width: '100%',
            EditableTextButton: {
              display: 'inline-block',
              border: '1px solid',
              borderColor: 'pigeon200',
              '&:hover': {
                borderColor: 'pigeon200',
              },
            },
          }}
          value={plainTextValue || ''}
        />
      </TransactionModal>
    );
  },
);
BulkEditPlainTextCustomFieldModal.displayName = 'BulkEditPlainTextCustomFieldModal';
