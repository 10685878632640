import { AssetCustomFieldValue, BoardCustomFieldValue } from '@air/api/types';
import { memo } from 'react';

import { EditableDate, EditableDateProps } from '~/components/Zephyr/EditableDate';
import { CUSTOM_FIELD_DATE } from '~/constants/testIDs';

export interface CustomFieldDateInputProps
  extends Omit<EditableDateProps, 'label' | 'name' | 'dateValue' | 'variant' | 'onEditingStateChange' | 'onChange'> {
  field: BoardCustomFieldValue | AssetCustomFieldValue;
  onBlur?: () => void;
  onChange: (date: Date | undefined) => void;
  canEditCustomFields: boolean;
}

export const CustomFieldDateInput = memo(
  ({ field, onBlur, onChange, canEditCustomFields, ...rest }: CustomFieldDateInputProps) => {
    return (
      <EditableDate
        {...rest}
        onChange={(date: Date | undefined) => {
          const dateValue = date?.toISOString();
          if (dateValue === field.dateValue) return;

          onChange(date);
        }}
        onEditingStateChange={(isEditing) => {
          if (!isEditing) {
            onBlur?.();
          }
        }}
        data-testid={`${CUSTOM_FIELD_DATE}-${field.name}`}
        variant="field-input-smol"
        label={field.name}
        name={field.id}
        dateValue={field.dateValue}
        tx={{ color: 'var(--colors-grey11)', width: '100%' }}
        placeholder={canEditCustomFields ? 'Add a date' : 'None'}
        readOnly={!canEditCustomFields}
        shouldShowCalendarIcon={false}
      />
    );
  },
);

CustomFieldDateInput.displayName = 'CustomFieldDateInput';
