import { Box } from '@air/zephyr';
import styled from 'styled-components';

import { TableCell } from '~/components/TableView/ui';

export const Container = styled(Box)`
  ${TableCell} {
    display: flex;
    min-height: 48px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
