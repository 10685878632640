import { CustomFieldValue, CustomFieldValueListResponse } from '@air/api/types';
import { TransactionModal } from '@air/zephyr';
import { differenceBy, intersectionBy, map } from 'lodash';
import pluralize from 'pluralize';
import { memo, useCallback, useMemo, useState } from 'react';

import { Select, SelectProps, SelectVariant } from '~/components/Zephyr/Select/Select';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';
import { BULK_EDIT_MULTI_SELECT_MODAL, CUSTOM_FIELD_SELECT_COMPONENT } from '~/constants/testIDs';
import { getCustomFieldSelectOption } from '~/utils/CustomFields';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditMultiSelectCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  cfOptions: CustomFieldValueListResponse | undefined;
  onSave: (props: {
    assetIds: string[];
    boardIds: string[];
    baseCustomField: BulkEditCustomFieldModalProps['field'];
    multiValueIdsToRemove: string[];
    multiValuesToAdd: CustomFieldValue[];
  }) => Promise<void>;
}

export const BulkEditMultiSelectCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues: _initialValues,
    onClose,
    cfOptions,
    onSave,
  }: AirModalProps<BulkEditMultiSelectCustomFieldModalProps>) => {
    const fieldValues = useMemo(() => cfOptions?.data || [], [cfOptions?.data]);

    const initialValues = intersectionBy(..._initialValues.map((cf) => cf.values || []), 'id').map(
      getCustomFieldSelectOption,
    );

    const [values, setValues] = useState<DefaultChipType[]>(initialValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const selectOptions = useMemo(() => {
      if (!cfOptions) return [];
      return cfOptions.data.map((option) => getCustomFieldSelectOption(option));
    }, [cfOptions]);

    const onSelectionChange: SelectProps<DefaultChipType>['onSelectionChange'] = useCallback(({ chips }) => {
      setIsTouched(true);
      setValues(chips);
    }, []);

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      const valueIdsToAdd = map(differenceBy(values, initialValues, 'value'), 'value');
      const multiValueIdsToRemove = map(differenceBy(initialValues, values, 'value'), 'value');
      const multiValuesToAdd = fieldValues.filter((v) => valueIdsToAdd.includes(v.id));

      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        multiValueIdsToRemove,
        multiValuesToAdd,
      });
      onClose();
    }, [values, initialValues, fieldValues, onSave, assetIds, boardIds, field, onClose]);
    const placeholder = useMemo(() => {
      if (isTouched) return 'None';
      return 'Find an option';
    }, [isTouched]);

    return (
      <TransactionModal
        data-testid={BULK_EDIT_MULTI_SELECT_MODAL}
        primaryCTA={{ children: 'Save', onClick: onConfirm, isLoading: isSubmitting, disabled: !isTouched }}
        secondaryCTA={{ children: 'Cancel', onClick: onClose }}
        variant="modal-large"
        modalLabel={`Edit ${field.name}`}
        onDismiss={onClose}
      >
        <p className="mb-6 text-16">
          {`Choose or remove a value or multiple values from the list below to apply to the ${pluralize(
            'item',
            assetIds.length + boardIds.length,
            true,
          )} you've selected.`}
        </p>
        <Select
          autoFocus
          data-testid={`${CUSTOM_FIELD_SELECT_COMPONENT}-${field.name}`}
          options={selectOptions}
          placeholder={placeholder}
          selectedOptions={values}
          isClearable
          isSearchable
          spacingStyles={{ width: '100%' }}
          maxDropdownHeight={300}
          onSelectionChange={onSelectionChange}
          isSingleSelect={false}
          variants={[SelectVariant.withBorder]}
          isLoading={!cfOptions}
        />
      </TransactionModal>
    );
  },
);
BulkEditMultiSelectCustomFieldModal.displayName = 'BulkEditMultiSelectCustomFieldModal';
