import { css } from 'styled-components';

const thumbColor = 'pigeon100';
const trackColor = 'white';
const thumbHover = 'pigeon200';
const thickness = 12;
const margin = 2;
const vertical = true;

const formatThickness = `
  ::-webkit-scrollbar {
    ${`${vertical ? 'width' : 'height'}: ${thickness}px`};
  }
`;

const formatTrackColor = css`
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors[trackColor]};
  }
`;

const formatThumb = css`
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors[thumbColor]};
    border: ${margin}px solid transparent;
    border-radius: ${thickness - margin}px;
    background-clip: content-box;
  }
`;

const formatThumbHover = css`
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors[thumbHover]};
    border: ${margin}px solid transparent;
    border-radius: ${thickness - margin}px;
    background-clip: content-box;
  }
`;

// Thumb hover color used for contrast due to lack of hover on custom firefox scrollbar
const formatFirefoxScrollbar = css`
  scrollbar-color: ${({ theme }) => `${theme.colors[thumbHover]} ${theme.colors[trackColor]}`};
  scrollbar-width: thin;
`;

/**
 * @description Hard codes a standard custom scrollbar used throughout the app.
 * No overriding props were ever passed through to customScrollBar when it was the formatScrollBar method.
 *  CSS for a custom firefox scrollbar is also generated albeit limited in style
 */

export const customScrollBar = css`
  ${formatThickness}
  ${formatTrackColor}
  ${formatThumb}
  ${formatThumbHover}
  ${formatFirefoxScrollbar}
`;
