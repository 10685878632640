import { useTrackClickedEllipsisMenuButton } from '@air/analytics';
import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { itemIsSelectedSelector, selectedItemsCountSelector } from '@air/redux-selected-items';
import pluralize from 'pluralize';
import { memo, useCallback, useMemo } from 'react';
import { flushSync } from 'react-dom';

import { CardEllipsisMenu } from '~/components/Gallery/CardEllipsisMenu';
import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { GetItemMenuOptions } from '~/constants/MenuOptions';
import { FILE_CARD_SUB_MENU } from '~/constants/testIDs';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

export interface FileGalleryCardMenuProps<C> {
  clip: C;
  getItemMenuOptions: GetItemMenuOptions;
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
  setIsMenuOpen: (value: boolean) => void;
  trigger: JSX.Element;
}

const _FileGalleryCardMenu = <C extends Clip | PublicClip>({
  clip,
  getItemMenuOptions,
  getSelectionMenuOptions,
  setIsMenuOpen,
  trigger,
}: FileGalleryCardMenuProps<C>) => {
  const { trackClickedEllipsisMenuButton } = useTrackClickedEllipsisMenuButton();

  /** Multiselect */
  const store = useAirStore();
  const selectedCount = selectedItemsCountSelector(store.getState());
  const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, clip.id));

  const selectionMenuOptions = getSelectionMenuOptions('gallery-multi-select-ellipsis-menu');
  const ellipsisMenuOptions = getItemMenuOptions({ trackLocation: 'gallery-item-ellipsis-menu' });

  const onMenuChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        trackClickedEllipsisMenuButton({ location: 'asset-item' });
      }

      flushSync(() => {
        setIsMenuOpen(isOpen);
      });
    },
    [setIsMenuOpen, trackClickedEllipsisMenuButton],
  );

  const menuOptions = useMemo(() => {
    const menuOptionsArray: DropdownMenuOption[] = [];

    if (isSelected && selectionMenuOptions.length) {
      menuOptionsArray.push({
        id: 'selected-header',
        type: 'title',
        label: `${pluralize('item', selectedCount, true)} selected`,
      });

      menuOptionsArray.push(...selectionMenuOptions);
    } else {
      menuOptionsArray.push(...ellipsisMenuOptions);
    }

    return menuOptionsArray;
  }, [ellipsisMenuOptions, isSelected, selectedCount, selectionMenuOptions]);

  if (!menuOptions?.length) {
    return null;
  }

  return (
    <CardEllipsisMenu
      title="More file options"
      menuProps={{ options: menuOptions }}
      onMenuChange={onMenuChange}
      actionSheetTestId={FILE_CARD_SUB_MENU}
      trigger={trigger}
    />
  );
};

export const FileGalleryCardMenu = memo(_FileGalleryCardMenu) as typeof _FileGalleryCardMenu;
