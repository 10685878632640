import { memo, useCallback } from 'react';

import { SelectProps } from '~/components/Zephyr/Select/Select';
import { SingleSelect } from '~/components/Zephyr/Select/SingleSelect';
import { DateRangeType } from '~/store/filters/types';

interface DateTypeOption {
  value: DateRangeType;
  label: string;
}

const dateTypeOptions: DateTypeOption[] = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last-week', label: 'Last week' },
  { value: 'last-month', label: 'Last month' },
  { value: 'last-year', label: 'Last year' },
  { value: 'custom-range', label: 'Custom date range' },
];

export interface DateFilterTypeSelectProps {
  selectedType: DateRangeType;
  onTypeChange: (type: DateRangeType) => void;
}

export const DateFilterTypeSelect = memo(({ selectedType, onTypeChange }: DateFilterTypeSelectProps) => {
  const onSelectionChange: SelectProps<DateTypeOption>['onSelectionChange'] = useCallback(
    ({ chips }) => {
      onTypeChange(chips[chips.length - 1].value);
    },
    [onTypeChange],
  );

  const selectedDate = dateTypeOptions.find((option) => option.value === selectedType);

  return (
    <SingleSelect
      variant="field-input-smol"
      options={dateTypeOptions}
      selectedOption={selectedDate}
      onSelectionChange={onSelectionChange}
      placeholder="Select date..."
      spacingStyles={{ mb: 16 }}
    />
  );
});

DateFilterTypeSelect.displayName = 'DateFilterTypeSelect';
