import { CustomFieldValue, CustomFieldValueListResponse } from '@air/api/types';
import { TransactionModal } from '@air/zephyr';
import pluralize from 'pluralize';
import { memo, useCallback, useMemo, useState } from 'react';

import { Select, SelectProps, SelectVariant } from '~/components/Zephyr/Select/Select';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';
import { BULK_EDIT_SINGLE_SELECT_MODAL, CUSTOM_FIELD_SELECT_COMPONENT } from '~/constants/testIDs';
import { getCustomFieldSelectOption } from '~/utils/CustomFields';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditSingleSelectCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  cfOptions: CustomFieldValueListResponse | undefined;
  onSave: (props: {
    assetIds: string[];
    boardIds: string[];
    nextValue: CustomFieldValue | null | undefined;
    baseCustomField: BulkEditCustomFieldModalProps['field'];
  }) => Promise<void>;
}

export const BulkEditSingleSelectCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues,
    onClose,
    cfOptions,
    onSave,
  }: AirModalProps<BulkEditSingleSelectCustomFieldModalProps>) => {
    const allHaveSameValue = useMemo(() => {
      const baseValue = initialValues[0].value;
      return initialValues.every((cf) => cf.value?.id === baseValue?.id || cf.value === baseValue);
    }, [initialValues]);

    const [values, setValues] = useState<DefaultChipType[]>(() => {
      if (!initialValues.length) return [];
      const baseValue = initialValues[0].value;
      if (!baseValue) return [];
      if (allHaveSameValue) return [getCustomFieldSelectOption(baseValue)];
      return [];
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const selectOptions = useMemo(() => {
      if (!cfOptions) return [];
      return cfOptions.data.map((option) => getCustomFieldSelectOption(option));
    }, [cfOptions]);
    const onSelectionChange: SelectProps<DefaultChipType>['onSelectionChange'] = useCallback(({ chips }) => {
      setIsTouched(true);
      setValues(chips);
    }, []);

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      const [rawVal] = values;
      const nextValue = (rawVal && cfOptions?.data.find((v) => v.id === rawVal.value)) || null;

      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        nextValue,
      });
      onClose();
    }, [assetIds, boardIds, cfOptions?.data, field, onClose, onSave, values]);

    const placeholder = useMemo(() => {
      if (isTouched) return 'None';
      if (allHaveSameValue) return 'Find an option';
      return 'Multiple Values';
    }, [allHaveSameValue, isTouched]);

    return (
      <TransactionModal
        data-testid={BULK_EDIT_SINGLE_SELECT_MODAL}
        primaryCTA={{
          children: 'Save',
          onClick: onConfirm,
          isLoading: isSubmitting,
          disabled: !isTouched,
        }}
        secondaryCTA={{ children: 'Cancel', onClick: onClose }}
        variant="modal-large"
        modalLabel={`Edit ${field.name}`}
        onDismiss={onClose}
      >
        <p className="mb-6 text-16">
          {`Choose or remove a value below to apply to the ${pluralize(
            'item',
            assetIds.length + boardIds.length,
            true,
          )} you've selected.`}
        </p>
        <Select
          autoFocus
          closeMenuOnSelect
          data-testid={`${CUSTOM_FIELD_SELECT_COMPONENT}-${field.name}`}
          options={selectOptions}
          placeholder={placeholder}
          selectedOptions={values}
          isSearchable
          spacingStyles={{ width: '100%' }}
          maxDropdownHeight={300}
          onSelectionChange={onSelectionChange}
          isSingleSelect
          isClearable
          variants={[SelectVariant.withBorder]}
          isLoading={!cfOptions}
        />
      </TransactionModal>
    );
  },
);
BulkEditSingleSelectCustomFieldModal.displayName = 'BulkEditSingleSelectCustomFieldModal';
