import { AirActionTrackingLocation } from '@air/analytics';
import { Board, Clip } from '@air/api/types';
import { Close } from '@air/next-icons';
import { useToasts } from '@air/provider-toast';
import { Box, IconButton, TransactionModal } from '@air/zephyr';
import pluralize from 'pluralize';
import React, { useCallback, useState } from 'react';

import {
  BoardSearchOption,
  PrivateBoardSearch,
  PrivateBoardSearchProps,
} from '~/components/BoardSearch/PrivateBoardSearch';
import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';
import { ListSectionHeader } from '~/components/Shared/ListSectionHeader';
import { ToastLink } from '~/components/UI/ToastLink';
import { ADD_CLIPS_TO_BOARD_MODAL } from '~/constants/testIDs';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useAddAssetsToBoards } from '~/swr-hooks/boards/useAddAssetsToBoards';
import { useRefreshAssetsInAllViews } from '~/utils/mutateUtils/GalleryAssets';
import { canAddAssetsToBoard } from '~/utils/permissions/boardPermissions';

export interface AddClipsToBoardModalProps {
  clipIds: Clip['id'][];
  trackLocation: AirActionTrackingLocation;
  onSubmit?: () => void;
  parentBoardId?: Board['id'];
}

const getInstructions = (isPlural: boolean) =>
  isPlural
    ? 'The assets will appear on a new board (or boards) and will not be removed from their current locations.'
    : 'The asset will appear on a new board (or boards) and will not be removed from its current location.';

export const AddAssetsToBoardsModal = ({
  clipIds,
  onClose,
  trackLocation,
  onSubmit,
  parentBoardId,
}: AirModalProps<AddClipsToBoardModalProps>) => {
  const { goToBoardPage } = useGoToBoardPage();
  const { showToast } = useToasts();
  const [adding, setAdding] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [selectedBoards, setSelectedBoards] = useState<BoardSearchOption[]>([]);
  const isBoardSelected = useCallback(
    (board: Pick<Board, 'id'>) => {
      return selectedBoards.some((b) => b.id === board.id);
    },
    [selectedBoards],
  );
  const { getBoardPermissions } = useBoardPermissionsCache();

  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { addAssetsToBoard } = useAddAssetsToBoards();
  const { refreshAssetsInAllViews } = useRefreshAssetsInAllViews();

  const onBoardSelectChange: PrivateBoardSearchProps['onBoardSelectChange'] = useCallback(({ board, selected }) => {
    setSelectedBoards((current) => {
      if (selected) {
        return [...current, board];
      } else {
        return current.filter((b) => b.id !== board.id);
      }
    });
  }, []);

  const getIsBoardDisabled: Required<PrivateBoardSearchProps>['getIsBoardDisabled'] = useCallback(
    (board) => {
      const boardPermissions = getBoardPermissions(board.id);

      if (!canAddAssetsToBoard(boardPermissions)) {
        return {
          isDisabled: true,
          message: 'You do not have permission to add assets to this board',
        };
      }
    },
    [getBoardPermissions],
  );

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const goToBoard = (board: Pick<Board, 'id' | 'title'>) => {
    goToBoardPage({ board, trackLocation: 'add-clips-to-board-toast' });
  };

  const handleAddClick = async () => {
    const boards = selectedBoards;

    setAdding(true);

    try {
      await addAssetsToBoard({
        clipIds,
        boards,
        trackLocation,
        parentBoardId,
      });

      // TODO: should this be in addAssetsToBoard?
      for (const board of boards) {
        refreshAssetsInAllViews({ parentBoardId: board.id });
      }

      onSubmit?.();
      onClose();
      showToast(
        <>
          {`${pluralize('item', clipIds.length, true)} added to `}
          {boards.length === 1 ? (
            <ToastLink onClick={() => goToBoard(boards[0])}>{boards[0].title}</ToastLink>
          ) : (
            <>multiple boards</>
          )}
        </>,
      );
    } catch (_error) {
      setAdding(false);
      showToast('An error occurred - please try again later');
    }
  };

  return (
    <TransactionModal
      tx={{ mt: [0, 60], borderRadius: [0, 6], minHeight: ['100%', 'unset'] }}
      onDismiss={onClose}
      modalLabel="Add to…"
      primaryCTA={{
        children: adding
          ? 'Adding…'
          : `Add to ${selectedBoards.length >= 1 ? pluralize('board', selectedBoards.length, true) : 'board'}`,
        onClick: handleAddClick,
        disabled: adding || !selectedBoards.length,
      }}
      secondaryCTA={{
        children: 'Cancel',
        onClick: onClose,
      }}
      data-testid={ADD_CLIPS_TO_BOARD_MODAL}
    >
      <p className="mb-4 text-14 text-grey-10">{getInstructions(clipIds.length > 1)}</p>
      <PrivateBoardSearch
        tx={{ mt: 12, mb: -16 }}
        isBoardSelected={isBoardSelected}
        onBoardSelectChange={onBoardSelectChange}
        workspaceNavTx={{ height: 240 }}
        shouldExpandLibraryInsteadOfSelect={true}
        getIsBoardDisabled={getIsBoardDisabled}
      />
      <ListSectionHeader
        className="mb-0 mt-9"
        showDowntick="always"
        onClick={() => setShowSummary(!showSummary)}
        collapsed={!showSummary}
        headerTitle={<>Selected boards ({selectedBoards.length})</>}
      />
      {showSummary &&
        selectedBoards.map((option, index) => (
          <Box
            key={index}
            tx={{
              bg: 'pigeon025',
              borderRadius: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 12,
              py: 8,
              my: 8,
            }}
          >
            <Box tx={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <BoardThumbnail board={option} sizes="32px" width={32} height={32} tx={{ cursor: 'unset' }} />
              <div>
                <div className="text-14 font-medium text-grey-11">{option.title}</div>
                <div className="text-12 text-grey-10">
                  {option.ancestors?.map((a) => a.title).join(' / ') || 'Workspace Boards'}
                </div>
              </div>
            </Box>
            <IconButton
              variant="button-ghost-grey"
              size="extra-small"
              icon={Close}
              tx={{ '& svg': { width: 12 } }}
              onClick={() => setSelectedBoards((boards) => boards.filter((b) => b.id !== option.id))}
            >
              Clear
            </IconButton>
          </Box>
        ))}
    </TransactionModal>
  );
};
