import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import React, { memo, ReactNode } from 'react';

import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { TableRow } from '~/components/TableView/TableRow/TableRow';
import { useSelectableCard, UseSelectableCardProps } from '~/hooks/useSelectableCard';
import { useSelectionAwareContextMenu } from '~/hooks/useSelectionAwareContextMenu';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { leftMouseEvent } from '~/utils/ClickUtils';

export interface SelectableTableRowProps<Item extends SelectableGalleryItem> {
  renderCells: (props: { isSelected: boolean; isHovering: boolean }) => ReactNode;
  contextMenuOptions: DropdownMenuOption[];
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
  onRowClick: () => void;
  item: UseSelectableCardProps<Item>['item'];
  isSelectable: boolean;
}

const _SelectableTableRow = <Item extends SelectableGalleryItem>({
  item,
  renderCells,
  getSelectionMenuOptions,
  contextMenuOptions,
  onRowClick,
  isSelectable,
}: SelectableTableRowProps<Item>) => {
  const {
    isHovering,
    handleHover,
    handleUnhover,
    isSelected,
    handleItemDoubleClick,
    confirmItemSelection,
    preSelectItem,
    onFocus,
    onBlur,
    itemRef: rowRef,
    isHighlighted,
  } = useSelectableCard({
    item,
    onClick: onRowClick,
    isSelectable,
  });

  const { openContextMenu, getContextMenu, isShowingContextMenu } = useSelectionAwareContextMenu({
    id: item.id,
    contextMenuOptions,
    trackLocationForMultiSelect: 'table-multi-select-context-menu',
    selectionMenuOptions: getSelectionMenuOptions('table-multi-select-context-menu'),
  });

  return (
    <TableRow
      tabIndex={0}
      onContextMenu={openContextMenu}
      onMouseDown={leftMouseEvent(preSelectItem)}
      onClick={confirmItemSelection}
      onDoubleClick={handleItemDoubleClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={handleHover}
      onMouseMove={handleHover}
      onMouseLeave={handleUnhover}
      ref={rowRef}
      className={
        isSelected && isHovering
          ? 'bg-blue-4'
          : isHighlighted || isSelected
          ? 'bg-blue-2'
          : isHovering
          ? 'bg-grey-3'
          : undefined
      }
    >
      {renderCells({ isSelected, isHovering })}
      {isHovering || isShowingContextMenu ? getContextMenu() : null}
    </TableRow>
  );
};

export const SelectableTableRow = memo(_SelectableTableRow) as typeof _SelectableTableRow;
