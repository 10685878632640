import styled from 'styled-components';

import { customScrollBar } from '~/utils/CustomScrollbar';

/**
 * @TODO - Remove this at a later date
 */
export const SelectedFiltersContainer = styled('div')`
  > div:not(:last-of-type) {
    margin-bottom: 8px;
  }

  ${customScrollBar}
`;
