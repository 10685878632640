import { Box, Button, Checkbox, TXProp } from '@air/zephyr';
import { noop } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';

export interface CheckboxButtonProps {
  isSelected: boolean;
  label: string;
  tx?: TXProp;
  onClick: (isSelected: boolean) => void;
  'data-testid'?: string;
  color?: string;
  size?: number;
}

const isWhiteColor = (color: string) => color === '#ffffff' || color === 'white';

export const CheckboxButton = memo(
  ({ isSelected, tx, label, onClick, 'data-testid': testId, color, size = 16 }: CheckboxButtonProps) => {
    const onButtonClick = useCallback(
      (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onClick(!isSelected);
      },
      [isSelected, onClick],
    );

    const checkboxStyles: TXProp = useMemo(() => {
      const colorStyles = color
        ? {
            [`input + ${Box}, input:checked + ${Box}`]: {
              backgroundColor: color,
              borderColor: isWhiteColor(color) ? 'inherit' : color,
            },

            // set checkbox icon color to black if background is white
            [`input + ${Box} > ${Box}`]: {
              color: isWhiteColor(color) ? 'black' : 'white',
              stroke: isWhiteColor(color) ? 'black' : 'white',
            },
          }
        : {};

      return { cursor: 'pointer', ...colorStyles };
    }, [color]);

    return (
      <Button
        data-testid={testId}
        variant="button-unstyled"
        tx={{
          borderRadius: 2,
          height: 16,
          [`input + ${Box}`]: { width: size, height: size },
          svg: { width: size - 4, height: size - 4 },
          ...tx,
        }}
        onClick={onButtonClick}
      >
        <Checkbox
          isLabelHidden={true}
          label={`Select ${label}`}
          onChange={noop}
          checked={isSelected}
          tx={checkboxStyles}
        />
      </Button>
    );
  },
);

CheckboxButton.displayName = 'CheckboxButton';
