import { SortByOption as TagsSortByOption } from '@air/api/types';
import { tailwindMerge } from '@air/tailwind-variants';
import { capitalize } from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { usePromise } from 'react-use';
import { useDebounce } from 'use-debounce';

import { getTagErrorMessage } from '~/components/Modals/Tags/utils';
import { TagsSelect, TagsSelectProps } from '~/components/TagsSelect/TagsSelect';
import { convertTagToSelectOption, TagBase, TagSelectOption } from '~/components/TagsSelect/utils';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCreateTag } from '~/swr-hooks/tags/useCreateTag';
import { usePrivateTags } from '~/swr-hooks/usePrivateTags';
import { canCreateTag } from '~/utils/permissions/workspacePermissions';

export interface PrivateTagsSelectProps
  extends Omit<TagsSelectProps, 'onInputChange' | 'tags' | 'selectedTags' | 'onTagsSelectionChange'> {
  selectedTags?: TagBase[];
  onSelectedTagsChanged: (newTags: TagBase[]) => void;
  canCreateNewTags?: boolean;
  sortBy?: TagsSortByOption;
  className?: string;
}

export const PrivateTagsSelect = memo(
  ({ selectedTags = [], onSelectedTagsChanged, canCreateNewTags, className, ...props }: PrivateTagsSelectProps) => {
    const { createTag } = useCreateTag();
    const [searchValue, setSearchValue] = useState('');
    const [error, setError] = useState('');
    const mounted = usePromise();
    const { data: permissions } = useCurrentWorkspacePermissionsContext();
    const canCreateTags = canCreateTag(permissions);

    const [debouncedSearchValue] = useDebounce(searchValue, 200, {
      trailing: true,
    });

    const { data = [], isLoading: isInitialLoading } = usePrivateTags({
      isTagsSelect: true,
      search: debouncedSearchValue,
    });

    const onCreateTag = useCallback(
      async (name: string) => {
        try {
          setError('');
          onSelectedTagsChanged([...selectedTags, { id: '', label: name }]);
          const [{ id, label }] = await mounted(createTag(name, 'select'));
          onSelectedTagsChanged([...selectedTags, { id, label }]);
        } catch (error) {
          onSelectedTagsChanged(selectedTags);
          setError(getTagErrorMessage(error, name));
        }
      },
      [createTag, mounted, onSelectedTagsChanged, selectedTags],
    );

    const onSelectionChange = useCallback(
      (values: TagSelectOption[]) => {
        setError('');
        const baseTags = values.map(({ value, label }) => ({ id: value, label }));
        onSelectedTagsChanged(baseTags);
      },
      [onSelectedTagsChanged],
    );

    return (
      <div className={className}>
        <TagsSelect
          tags={data}
          maxDropdownHeight={280}
          isLoading={isInitialLoading}
          selectedTags={selectedTags.map((tag) => convertTagToSelectOption(tag))}
          onTagsSelectionChange={onSelectionChange}
          onInputChange={setSearchValue}
          onCreate={canCreateNewTags && canCreateTags ? onCreateTag : undefined}
          {...props}
        />
        <span
          role="alert"
          className={tailwindMerge('absolute text-12 text-red-9', !!error ? 'block' : 'hidden')}
          aria-hidden={!error}
        >
          {capitalize(error)}
        </span>
      </div>
    );
  },
);

PrivateTagsSelect.displayName = 'PrivateTagsSelect';
